import React from 'react';
import PropTypes from 'prop-types';

import AddItem from '../../../../nucleus/add-item/add-item';
import CustomConsiderationField from './components/customConsiderationsField';
import FileUploadAccordian from '../../file-upload-accordian';
import { getRandomId } from '../../../../utils/validation-utils';

const Considerations = ({
  modalFieldState,
  modalFieldChangeHandler,
  individualModalFieldChangeHandler,
  fieldErrorMap,
}) => (
  <FileUploadAccordian accordianTitle="Considerations">
    <p className="file-modal-sub-heading">Consideration Breakdown</p>
    <>
      {modalFieldState.customConsideration.value.map(customConsideration => (
        <CustomConsiderationField
          modalFieldState={modalFieldState}
          modalFieldChangeHandler={modalFieldChangeHandler}
          individualModalFieldChangeHandler={individualModalFieldChangeHandler}
          fieldErrorMap={fieldErrorMap}
          currentCustomConsideration={customConsideration}
          key={customConsideration.id}
        />
    ))}
      <div className="file-modal-add-button">
        <AddItem
          label="Add Rent"
          onClick={() => {
          const newCustomConsiderationValue = [
            ...modalFieldState.customConsideration.value,
          ];
          newCustomConsiderationValue.push({
            id: getRandomId(),
            consideration_type: 'Annual Rent',
            total_units: '',
            amount: '',
            consideration_amount: '',
            commission_percent: '',
            commission_dollars: '',
            commission_amount: '',
          });
          modalFieldChangeHandler('customConsideration', {
            value: newCustomConsiderationValue,
          });
          individualModalFieldChangeHandler({
            child_value: newCustomConsiderationValue,
            parent_key: 'consideration_info',
            child_key: modalFieldState.customConsideration.trackingID,
            stateVariable: 'customConsideration',
          });
        }}
        />
      </div>
    </>
  </FileUploadAccordian>
);

Considerations.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  individualModalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
};

Considerations.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  individualModalFieldChangeHandler: () => {},
  fieldErrorMap: {},
};

export default Considerations;
