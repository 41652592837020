import React from 'react';
import PropTypes from 'prop-types';
import Considerations from './sections/considerations';

const Financials = ({
  modalFieldState, modalFieldChangeHandler, individualModalFieldChangeHandler, fieldErrorMap,
}) => (
  <>
    <Considerations
      modalFieldState={modalFieldState}
      modalFieldChangeHandler={modalFieldChangeHandler}
      individualModalFieldChangeHandler={individualModalFieldChangeHandler}
      fieldErrorMap={fieldErrorMap}
    />
  </>
);

Financials.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  individualModalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
};

Financials.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  individualModalFieldChangeHandler: () => {},
  fieldErrorMap: {},
};

export default Financials;
