import React from 'react';
import { RadioGroup, RadioButton } from '@cbrebuild/blocks';
import PropTypes from 'prop-types';

// import Tooltip from '../../nucleus/tooltip/tooltip';
import Datepicker from '../../nucleus/datepicker/datepicker';
import Select from '../../nucleus/select/select';

import './lease-abstraction.scss';
import PhoneInput from '../../nucleus/inputs/phone-input';
import PercentDollarInput from '../../nucleus/inputs/PercentDollarInput';

const LeaseAbstractionFieldType = Object.freeze({
  DATE: 0,
  TEXT: 1,
  TEXT_WITH_LABEL: 2,
  SELECT: 3,
  SELECT_WITH_LABEL: 4,
  RADIO: 5,
  PHONE: 6,
  PERCENT_DOLLAR: 7,
  READ_ONLY: 8,
});

const LeaseAbstractionField = ({
  fieldType,
  fieldData,
  fieldChangeHandler,
  fieldValidityChangeHandler,
  validateLeaseAbstractionFields,
  individualFieldChangeHandler,
  asGroup,
  inputText,
  disabled,
  customPercentDollarField,
  showToolTip,
  percentFieldBaseTotal,
  percentFieldCustomIsPercent,
  percentFieldCustomOnTypeChange,
  percentageFieldCustomValue,
  percentageFieldCustomPercentage,
  percentDollarHideConversion,
}) => {
  const handleFieldChange = (event) => {
    // Call the existing handler
    fieldChangeHandler(event);

    // Call the individual field change handler
    individualFieldChangeHandler(event);
  };

  const handleFieldBlur = (event) => {
    // Call the existing handler
    validateLeaseAbstractionFields(event);

    // Call the individual field change handler
    individualFieldChangeHandler(event);
  };

  switch (fieldType) {
    case LeaseAbstractionFieldType.DATE:
      return (
        <div className="file-modal-group-container">
          <div className="file-modal-group">
            <label className="acc-field-label">
              <span>{fieldData.label}</span>
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip?.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip?.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
            </label>
            <Datepicker
              date={fieldData.data.value}
              onDateChange={handleFieldChange}
              updateValidity={fieldValidityChangeHandler}
            />
          </div>
          {showToolTip && fieldData.data.toolTip?.pageIndex?.length > 0 && (
            <p className="acc-page-info">
              <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
            </p>
          )}
        </div>
      );
    case LeaseAbstractionFieldType.RADIO:
      return (
        <div className="file-modal-group-container">
          <div className="acc-radio-group">
            <div className="acc-radio-group-label-container">
              <legend className="acc-radio-group-label">
                {fieldData.label}
              </legend>
              {showToolTip && fieldData.data.toolTip?.pageIndex?.length > 0 && (
                <p className="acc-page-info">
                  <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                </p>
              )}
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip?.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip?.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
            </div>
            <RadioGroup
              className="acc-radio-group-options"
              selectedValue={`${fieldData.data.value.toString().toLowerCase()}`}
              onChange={handleFieldChange}
              orientation="vertical"
            >
              {fieldData.radioButtons.map(({ value, text }) => (
                <RadioButton
                  key={text}
                  checked={
                    fieldData.data.value.toString().toLowerCase() === value
                  }
                  className="acc-radio-group-label"
                  value={value}
                >
                  {text}
                </RadioButton>
              ))}
            </RadioGroup>
          </div>
        </div>
      );
    case LeaseAbstractionFieldType.TEXT:
      return (
        <div className="file-modal-group-container">
          <div
            className={`${
              asGroup ? 'file-modal-group-full' : 'file-modal-group'
            }`}
          >
            <label
              className={`${
                fieldData.label && fieldData.label.length > 0
                  ? 'acc-field-label'
                  : 'acc-field-label acc-field-label--transparent'
              }`}
            >
              {fieldData.label || 'Placeholder'}
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip?.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip?.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
            </label>
            <div className={`${inputText ? 'file-modal-input-with-text' : ''}`}>
              <input
                disabled={disabled}
                type="text"
                className="acc-input-text"
                value={fieldData.data.value}
                placeholder={inputText || fieldData.label}
                onChange={fieldChangeHandler}
                onBlur={handleFieldBlur}
              />
              <p className="file-modal-input-text">
                <span>{inputText}</span>
              </p>
            </div>

            {fieldData?.errorMessage?.length > 0 && (
              <p className="acc-error-message">{fieldData?.errorMessage}</p>
            )}
          </div>
          {showToolTip && fieldData.data.toolTip?.pageIndex?.length > 0 && (
            <p className="acc-page-info">
              <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
            </p>
          )}
        </div>
      );
    case LeaseAbstractionFieldType.TEXT_WITH_LABEL:
      return (
        <div className="file-modal-group-container">
          <div
            className={`${
              asGroup ? 'file-modal-group-full' : 'file-modal-group'
            }`}
          >
            <label
              className={`${
                fieldData.label && fieldData.label.length > 0
                  ? 'acc-field-label'
                  : 'acc-field-label acc-field-label--transparent'
              }`}
            >
              {fieldData.label || 'Placeholder'}
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip?.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip?.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
              {showToolTip && fieldData.data.toolTip?.pageIndex?.length > 0 && (
                <p className="acc-page-info">
                  <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                </p>
              )}
            </label>
            <div className={`${inputText ? 'file-modal-input-with-text' : ''}`}>
              <input
                disabled={disabled}
                type="text"
                className="acc-input-text"
                value={fieldData.data.value}
                placeholder={inputText || fieldData.label}
                onChange={fieldChangeHandler}
                onBlur={handleFieldBlur}
              />
              <p className="file-modal-input-text">
                <span>{inputText}</span>
              </p>
            </div>

            {fieldData?.errorMessage?.length > 0 && (
              <p className="acc-error-message">{fieldData?.errorMessage}</p>
            )}
          </div>
        </div>
      );
    case LeaseAbstractionFieldType.SELECT:
      return (
        <div className="file-modal-group-container">
          <div
            className={`${
              asGroup ? 'file-modal-group-full' : 'file-modal-group'
            }`}
          >
            <label className="acc-field-label">
              {fieldData.label}
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip?.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip?.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
            </label>
            <Select
              defaultOption={
                fieldData.data.value?.length > 0
                  ? fieldData.data.value
                  : 'Select'
              }
              showDefault={fieldData.data.value?.length === 0}
              options={fieldData.options}
              onChange={handleFieldChange}
            />
          </div>
          {showToolTip && fieldData.data.toolTip?.pageIndex?.length > 0 && (
            <p className="acc-page-info" >
              <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
            </p>
          )}
        </div>
      );
    case LeaseAbstractionFieldType.SELECT_WITH_LABEL:
      return (
        <div className="file-modal-group-container">
          <div
            className={`${
              asGroup ? 'file-modal-group-full' : 'file-modal-group'
            }`}
          >
            <label className="acc-field-label">
              {fieldData.label}
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip?.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip?.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
              {showToolTip && fieldData.data.toolTip?.pageIndex?.length > 0 && (
                <p className="acc-page-info">
                  <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                </p>
              )}
            </label>
            <Select
              defaultOption={
                fieldData.data.value?.length > 0
                  ? fieldData.data.value
                  : 'Select'
              }
              showDefault={fieldData.data.value?.length === 0}
              options={fieldData.options}
              onChange={handleFieldChange}
            />
          </div>
        </div>
      );
    case LeaseAbstractionFieldType.PHONE:
      return (
        <div className="file-modal-group-container">
          <div className="file-modal-group">
            <label className="acc-field-label">
              {fieldData.label}
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip?.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip?.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
            </label>
            <PhoneInput
              value={fieldData.data.value}
              onChange={fieldChangeHandler}
              onBlur={individualFieldChangeHandler}
              hideLabel
            />
          </div>
          {showToolTip && fieldData.data.toolTip?.pageIndex?.length > 0 && (
            <p className="acc-page-info">
              <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
            </p>
          )}
        </div>
      );
    case LeaseAbstractionFieldType.PERCENT_DOLLAR:
      return (
        <div className="file-modal-group-container">
          <div
            className={`${
              asGroup ? 'file-modal-group-full' : 'file-modal-group'
            }`}
          >
            <label className="acc-field-label">
              {fieldData.label}
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip?.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip?.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
            </label>
            <PercentDollarInput
              value={
                percentageFieldCustomValue !== undefined
                  ? percentageFieldCustomValue
                  : parseFloat(fieldData.data.value[
                        `${customPercentDollarField?.value || 'dollars'}`
                      ])
              }
              hideConversion={percentDollarHideConversion}
              disabled={disabled}
              name={
                customPercentDollarField || {
                  percentage: 'percentage',
                  value: 'dollars',
                  select: 'isPercent',
                }
              }
              onChange={(e) => {
                fieldChangeHandler({
                  value: {
                    ...fieldData.data.value,
                    [e.target.name[0]]: e.target.value[e.target.name[0]],
                    [e.target.name[1]]: e.target.value[e.target.name[1]],
                  },
                });
              }}
              onBlur={individualFieldChangeHandler}
              onTypeChange={
                percentFieldCustomOnTypeChange !== undefined
                  ? (e) => {
                      percentFieldCustomOnTypeChange(e);
                    }
                  : (e) => {
                      fieldChangeHandler({
                        value: {
                          ...fieldData.data.value,
                          [e.target.name]: e.target.value,
                        },
                      });
                    }
              }
              baseTotal={parseFloat(percentFieldBaseTotal)}
              percentage={
                percentageFieldCustomPercentage !== undefined
                  ? percentageFieldCustomPercentage
                  : parseFloat(fieldData.data.value[
                        `${
                          customPercentDollarField?.percentage || 'percentage'
                        }`
                      ])
              }
              isPercent={
                percentFieldCustomIsPercent !== undefined
                  ? percentFieldCustomIsPercent
                  : fieldData.data.value[
                      `${customPercentDollarField?.select || 'isPercent'}`
                    ]
              }
              hideLabel
            />
          </div>
          {showToolTip && fieldData.data.toolTip?.pageIndex?.length > 0 && (
            <p className="acc-page-info">
              <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
            </p>
          )}
        </div>
      );
    case LeaseAbstractionFieldType.READ_ONLY:
      return (
        <div className="file-modal-group-container">
          <div
            className={`${
              asGroup ? 'file-modal-group-full' : 'file-modal-group'
            }`}
          >
            <label
              className={`${
                fieldData.label && fieldData.label.length > 0
                  ? 'acc-field-label'
                  : 'acc-field-label acc-field-label--transparent'
              }`}
            >
              {fieldData.label || 'Placeholder'}
              {showToolTip && fieldData.data.toolTip?.pageIndex?.length > 0 && (
                <p className="acc-page-info">
                  <span>Page: {fieldData.data.toolTip?.pageIndex}</span>
                </p>
              )}
            </label>
            <div className={`${inputText ? 'file-modal-input-with-text' : ''}`}>
              {/* <input
                disabled={disabled}
                type="text"
                className="acc-input-text"
                value={fieldData.data.value}
                placeholder={inputText || fieldData.label}
                onChange={fieldChangeHandler}
                onBlur={handleFieldBlur}
              /> */}
              <div className="acc-read-only">{fieldData.data.value}</div>
              <p className="file-modal-input-text">
                <span>{inputText}</span>
              </p>
            </div>

            {fieldData?.errorMessage?.length > 0 && (
              <p className="acc-error-message">{fieldData?.errorMessage}</p>
            )}
          </div>
        </div>
      );
    default:
      break;
  }
  return <div />;
};

LeaseAbstractionField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fieldData: PropTypes.object,
  fieldType: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  fieldChangeHandler: PropTypes.func,
  individualFieldChangeHandler: PropTypes.func,
  fieldValidityChangeHandler: PropTypes.func,
  validateLeaseAbstractionFields: PropTypes.func,
  asGroup: PropTypes.bool,
  inputText: PropTypes.string,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  customPercentDollarField: PropTypes.object,
  showToolTip: PropTypes.bool,
  percentFieldBaseTotal: PropTypes.number,
  percentFieldCustomIsPercent: PropTypes.bool,
  percentFieldCustomOnTypeChange: PropTypes.func,
  percentageFieldCustomValue: PropTypes.number,
  percentageFieldCustomPercentage: PropTypes.number,
  percentDollarHideConversion: PropTypes.bool,
};

LeaseAbstractionField.defaultProps = {
  fieldData: {},
  fieldType: 1,
  fieldChangeHandler: () => {},
  individualFieldChangeHandler: () => {},
  fieldValidityChangeHandler: () => {},
  validateLeaseAbstractionFields: () => {},
  asGroup: false,
  inputText: '',
  disabled: false,
  customPercentDollarField: {},
  showToolTip: true,
  percentFieldBaseTotal: 0,
  percentFieldCustomIsPercent: undefined,
  percentFieldCustomOnTypeChange: undefined,
  percentageFieldCustomValue: undefined,
  percentageFieldCustomPercentage: undefined,
  percentDollarHideConversion: false,
};

export { LeaseAbstractionField, LeaseAbstractionFieldType };
