import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../../leaseAbstractionField';

import '../../../lease-abstraction.scss';

const CustomLeaseEscalationField = ({
  modalFieldState,
  modalFieldChangeHandler,
  individualModalFieldChangeHandler,
  fieldErrorMap,
  currentCustomLeaseEscalation,
}) => {
  const convertToYearsAndMonths = (months) => {
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    return { years, months: remainingMonths };
  };
  const handleCustomFieldChange = (event, field) => {
    const currentValue = [...modalFieldState.customLeaseEscalation.value];
    const newCustomLeaseEscalationValue = { ...currentCustomLeaseEscalation };
    const { months, years } = convertToYearsAndMonths(currentCustomLeaseEscalation.months);
    switch (field) {
      case 'rent':
        newCustomLeaseEscalationValue.rent = event.value;
        break;
      case 'years':
        newCustomLeaseEscalationValue.months = (parseFloat(event.target.value.length === 0 ? 0 : event.target.value) * 12) + months;
        break;
      case 'months':
        newCustomLeaseEscalationValue.months = (years * 12) + parseFloat(event.target.value.length === 0 ? 0 : event.target.value);
        break;
      default:
        break;
    }
    currentValue.splice(
      currentValue.findIndex(rent => rent.id === newCustomLeaseEscalationValue.id),
      1,
      newCustomLeaseEscalationValue,
    );
    modalFieldChangeHandler('customLeaseEscalation', {
      value: currentValue,
    });
    if (field === 'rent') {
      const child_value = currentValue.map(item => ({
        months: String(item.months),
        rent_dollar: String(item.rent.rent_dollar),
        rent_percent: String(item.rent.rent_percent),
        is_percent: String(item.rent.is_percent),
      }));
      individualModalFieldChangeHandler({
        child_value,
        parent_key: 'comp_info',
        child_key: modalFieldState.customLeaseEscalation.trackingID,
        stateVariable: 'customLeaseEscalation',
      });
    }
  };

  const handleIndividualFieldChange = () => {
    const newCustomLeaseEscalationValue = [
      ...modalFieldState.customLeaseEscalation.value,
    ];
    const child_value = newCustomLeaseEscalationValue.map(item => ({
      months: String(item.months),
      rent_dollar: String(item.rent.rent_dollar),
      rent_percent: String(item.rent.rent_percent),
      is_percent: String(item.rent.is_percent),
    }));
    individualModalFieldChangeHandler({
      child_value,
      parent_key: 'comp_info',
      child_key: modalFieldState.customLeaseEscalation.trackingID,
      stateVariable: 'customLeaseEscalation',
    });
  };
  return (
    <div className="file-modal-flex-group">
      <LeaseAbstractionField
        asGroup
        percentDollarHideConversion
        customPercentDollarField={{
            percentage: 'rent_percent',
            value: 'rent_dollar',
            select: 'is_percent',
          }}
        key="escalationPerYear"
        fieldType={LeaseAbstractionFieldType.PERCENT_DOLLAR}
        fieldData={{
          data: {
            value: currentCustomLeaseEscalation.rent,
          },
          label: 'Escalation (per year)',
          errorMessage: fieldErrorMap.escalationPerYear,
        }}
        fieldChangeHandler={(event) => {
          handleCustomFieldChange(event, 'rent');
        }}
        individualFieldChangeHandler={() => {
          handleIndividualFieldChange();
         }
        }
      />
      <LeaseAbstractionField
        asGroup
        key="freeRentYears"
        fieldType={LeaseAbstractionFieldType.TEXT_WITH_LABEL}
        fieldData={{
          data: {
            value: convertToYearsAndMonths(currentCustomLeaseEscalation.months).years,
          },
          label: 'Year',
          errorMessage: fieldErrorMap.freeRentYears,
        }}
        fieldChangeHandler={(event) => {
          const newValue = event.target.value;
          const regex = /^\d+(\.\d{0,2})?$/;
          if (regex.test(newValue) || newValue === '') {
            handleCustomFieldChange(event, 'years');
          }
        }}
        individualFieldChangeHandler={() => {
          handleIndividualFieldChange();
         }
        }
      />
      <LeaseAbstractionField
        asGroup
        key="freeRentMonths"
        fieldType={LeaseAbstractionFieldType.TEXT_WITH_LABEL}
        fieldData={{
          data: {
            value: convertToYearsAndMonths(currentCustomLeaseEscalation.months).months,
          },
          label: 'Month',
          errorMessage: fieldErrorMap.freeRentMonths,
        }}
        fieldChangeHandler={(event) => {
          const newValue = event.target.value;
          const regex = /^\d+(\.\d{0,2})?$/;
          if (regex.test(newValue) || newValue === '') {
            handleCustomFieldChange(event, 'months');
          }
          // modalFieldChangeHandler('freeRentMonths', {
          //   value: event.target.value,
          // });
        }}
        individualFieldChangeHandler={() => {
          handleIndividualFieldChange();
         }
        }
      />
      <IconButton
        className="blxs-button-icon-small"
        iconName="close-circle"
        onClick={() => {
          const newCustomLeaseEscalationValue = [
            ...modalFieldState.customLeaseEscalation.value,
          ];
          newCustomLeaseEscalationValue.splice(
            newCustomLeaseEscalationValue.findIndex(item => item.id === currentCustomLeaseEscalation.id),
            1,
          );
          modalFieldChangeHandler('customLeaseEscalation', {
            value: newCustomLeaseEscalationValue,
          });
          const child_value = newCustomLeaseEscalationValue.map(item => ({
            months: String(item.months),
            rent_dollar: String(item.rent.rent_dollar),
            rent_percent: String(item.rent.rent_percent),
            is_percent: String(item.rent.is_percent),
          }));
          individualModalFieldChangeHandler({
            child_value,
            parent_key: 'comp_info',
            child_key: modalFieldState.customLeaseEscalation.trackingID,
            stateVariable: 'customLeaseEscalation',
          }); // Clear the child value
        }}
        variant="basic"
      />
    </div>
  );
};

CustomLeaseEscalationField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  individualModalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  currentCustomLeaseEscalation: PropTypes.object,
};

CustomLeaseEscalationField.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  individualModalFieldChangeHandler: () => {},
  fieldErrorMap: {},
  currentCustomLeaseEscalation: {},
};

export default CustomLeaseEscalationField;
